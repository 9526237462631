import { ListItemText, Autocomplete, TextField } from "@mui/material";
import Grid from '@mui/material/Grid2';
import { useEffect, useState, useCallback } from "react";
import { ElementReference } from "../../api/types";
import AlertSnackbar from "../alertSnackbar";
import { getSelectedTaxonomyIds } from "../../helpers/taxonomyHelpers";
import { usePostQuery } from "../../hooks/usePostQuery";
import { ElementDetailedView } from "./elementDetailedView";

export const ElementLabel = ({
    label,
    selectedValue,
    setSelectedValue,
    value,
    onValueChange,
    preDefinedOptions,
    placeholder,
    disabled
}: {
    label: string | undefined;
    preDefinedOptions?: any[];
    selectedValue: any | null;
    setSelectedValue: (value: any | null) => void;
    value?: ElementReference;
    onValueChange?: (event: any) => void;
    placeholder?: string;
    disabled?: boolean;
}) => {

    //TODO ADD TYPES
    const [query, setQuery] = useState("");
    const [optionsState, setOptionsState] = useState<any[]>(preDefinedOptions ?? []);
    const [cacheBuster] = useState(crypto.randomUUID());
    const [abortSignal, setAbortSignal] = useState(true);
    const [req, setReq] = useState({
        searchString: query,
        pageCount: 20,
        taxonomyIds: getSelectedTaxonomyIds(),
    });

    const debounce = (func: Function, delay: number) => {
        let timer: NodeJS.Timeout;
        return (...args: any) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };

    const handleQueryChange = useCallback(debounce((newQuery: string) => {
        setReq({
            searchString: newQuery,
            pageCount: 20,
            taxonomyIds: getSelectedTaxonomyIds(),
        });
        setAbortSignal(false);
    }, 500), []);

    useEffect(() => {
        if (query.length > 3) {
            handleQueryChange(query);
        }
    }, [query, handleQueryChange]);

    useEffect(() => {
        if (value) {
            setSelectedValue(value);
        }
    }, [value, setSelectedValue]);

    const { data, loading, error } = usePostQuery<any[]>(
        `/api/custom-objects/element-references/search?cb=${cacheBuster}`,
        req,
        abortSignal
    );
    useEffect(() => {
        if (data) {
            setOptionsState(data as any[]);
        }
    }, [data]);

    return (
        <Grid container spacing={0} sx={{ mb: 2 }} maxWidth={"false"}>
            {error &&
                <AlertSnackbar
                    alertMessage={{ message: error.message, severity: "error" }}
                    updateAlertStatus={() => { }}
                />
            }
            <Grid size={4}>
                <ListItemText primary={label} primaryTypographyProps={{ fontWeight: 600, fontSize: 14 }} />
            </Grid>
            <Grid size={8}>
                <Autocomplete
                    forcePopupIcon={false}
                    options={optionsState}
                    getOptionLabel={(option) => option.taxonomyElement?.name || option?.extension?.name || ""}
                    renderOption={(props, option) => (
                        <li {...props}
                            style={{
                                margin: 0,
                                padding: 0,
                                listStyleType: "none",
                            }}
                        >
                            {option && <ElementDetailedView element={option} />}
                        </li>
                    )}
                    loading={loading}
                    value={selectedValue || null}
                    onChange={(_, newValue) => {
                        setSelectedValue(newValue || null);
                        if (onValueChange && newValue) {
                            console.log("changingValue", newValue);
                            onValueChange(newValue as any);
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder={placeholder || ""}
                            sx={{ width: "100%" }}
                            variant="standard"
                            disabled={disabled}
                            onChange={(event) => setQuery(event.target.value)}
                        />
                    )}
                />
                {selectedValue && selectedValue.extension && <ElementDetailedView element={selectedValue} />}
            </Grid>
        </Grid>
    );
};
