import { useState, useEffect, ChangeEvent, useRef, useMemo } from 'react';
import {
    Dialog,
    AppBar,
    Tabs,
    Tab,
    DialogContent,
    Button,
    Box,
    Container,
    DialogActions,
    DialogTitle,
    DialogContentText,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import LabeledTextField from '../labeledTextField/labeledTextField';
import { Loader } from '../loader';
import {
    tagsTemplate,
    headerTemplate,
    customDimensionsTemplate,
    dimensionValueTemplate,
    extensionElementsTemplate,
    settingsTemplate,
    ContextSettingsTemplate,
    labelsTemplate,
    dimensionValuesColumns,
    taxonomyElementTemplate,
} from './customObjectsPreData';
import { t } from '@lingui/macro';
import { cCustomDimensionValueDetails, cLabel, cTaxonomyElement, dataMatchingToNavBar, ElementReference, expandedTaxonomyElement, selectTableProps, TaxonomyListProps } from '../../api/types';
import SelectTaxonomies from './selectTaxonomies';
import { getAllTaxonomies } from '../../helpers/taxonomyHelpers';
import GenericGridTable from './genericGridTable';
import axios from 'axios';
import LabelTable from '../labelTable';
import UsageComponent from './usageComponent';
import DataTable from '../dataTable';
import { mapDataToTemplate, mapTemplateToData } from './mapDataToTemplate';
import DataTableWithFetch from '../dataTableWitchFetch';
import { getAlternativeColumnsMapping } from './columnsMapping';
import { generalPost, generalPut } from '../../api/general';
import { getLanguages } from '../../api/customObjects';

interface AddDialogProps {
    open: boolean;
    onClose: (updateStatus: "none" | "success" | "error", reason?: string) => void;
    selectTemplate: string;
    id: string | undefined;
    url: string;
}

const CustomObjectsDialog = ({ open, onClose, selectTemplate, id, url }: AddDialogProps) => {
    const [data, setData] = useState<dataMatchingToNavBar | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [activeTab, setActiveTab] = useState("details");
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const hasRun = useRef(false);
    const openDataDialog = useRef(false);

    const [languages, setLanguages] = useState<string[]>([]);

    useEffect(() => {
        if (!languages.length && activeTab === "dimensionLabels") {
            getLanguages().then((response) => {
                setLanguages(response);
            });

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab]);

    const handleTaxonomySetter = (customObjectsSelectedTaxonomies: TaxonomyListProps[]) => {
        setData((prevData: dataMatchingToNavBar | null): dataMatchingToNavBar | null => {
            if (!prevData) return prevData;

            localStorage.setItem('selectedTaxonomies', JSON.stringify(customObjectsSelectedTaxonomies));

            return {
                ...prevData,
                taxonomies: customObjectsSelectedTaxonomies.map(taxonomy => taxonomy.id),
            };
        });
    };

    const templateDataSelected = useMemo(() => {
        switch (selectTemplate) {
            case "tags":
                return tagsTemplate();
            case "header":
                return headerTemplate();
            case "labels":
            case "tableItems":
                return labelsTemplate();
            case "customDimensions":
                return customDimensionsTemplate();
            case "dimensionValue":
                return dimensionValueTemplate();
            case "extensionElements":
                return extensionElementsTemplate();
            case "settings":
                return settingsTemplate();
            case "contextSettings":
                return ContextSettingsTemplate();
            case "taxonomyElement":
                return taxonomyElementTemplate();
            default:
                return tagsTemplate();
        }
    }, [selectTemplate]);

    type IncomingData = any;

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                if (url && id) {
                    const response = await axios.get(`api/custom-objects/${url}/${id}`);
                    const apiData: IncomingData = response.data;
                    const mappedLabelsTemplate = mapDataToTemplate(templateDataSelected, apiData, selectTemplate);
                    setData(mappedLabelsTemplate);
                } else {
                    setData(templateDataSelected);
                }

                const allTaxonomies = await getAllTaxonomies();
                const selectedTaxonomyIds = allTaxonomies
                    .filter(taxonomy => taxonomy.isSelected)
                    .map(taxonomy => taxonomy.id);

                setData(prevData => {
                    if (!prevData || !prevData.taxonomies) return prevData;
                    return {
                        ...prevData,
                        taxonomies: selectedTaxonomyIds
                    };
                });
            } catch (error) {
                console.error('Error fetching data', error);
            } finally {
                setIsLoading(false);
                hasRun.current = true;
            }
        };

        if (!hasRun.current) {
            fetchData();
        }
    }, [url, id, templateDataSelected]);


    const handleTabChange = (event: ChangeEvent<{}>, newValue: string) => {
        setActiveTab(newValue);
    };

    const pagesShouldNotBeMapped = useMemo(() => [
        "globals",
        "usage",
        "dimensionValueReferences",
        "taxonomies",
        "extension",
        "childDimensionValues",
        "childElements",
        "documentation",
        "labels",
        "additionalElementReferences",
        "extensionStructureReferences",
        "hierarchyElementReferences",
        "customLabels",
        "customHeaders",
        "customTags",
        "customDimensionValues",
        "dimensionLabels",
        "contexts",
        "valueLabels",
        "validators",
        "childElementReferences",
    ], []);

    //extensionStructure / hierarchyElementReferences

    const handleApply = async () => {
        const link = `api/custom-objects/${url}${id ? `/${id}` : ''}`;
        const method = id ? generalPut : generalPost;

        try {
            await method(link, mapTemplateToData(data, id || null, url));
            console.log(mapTemplateToData(data, id || null, url))

            hasRun.current = false;
            openDataDialog.current = true;
            setActiveTab("details");
            onClose("success", `${id ? t`Updated Sucessfully` : t`Created Sucessfully`}`);
        } catch (error) {
            console.error("Error during handleApply:", error);

            hasRun.current = false;
            openDataDialog.current = true;
            setActiveTab("details");
            onClose("error", "failed");
        }
    };


    const handleClose = () => {
        onClose("none");
        hasRun.current = false;
    };

    const handleChange = (
        event: ElementReference | cTaxonomyElement | string | boolean | any,
        key: string
    ) => {
        let inputValue: any;

        if (typeof event === "string" || typeof event === "boolean") {
            inputValue = event;
        } else if ('target' in event && event.target instanceof HTMLInputElement) {
            const { type, checked, value } = event.target;
            inputValue = type === "checkbox" ? checked : value;
        } else {
            inputValue = event;
        }

        setData((prevData: dataMatchingToNavBar | null) => {
            if (!prevData || !prevData[activeTab]) return prevData;
            const updatedData = {
                ...prevData,
                [activeTab]: {
                    ...prevData[activeTab],
                    [key]: {
                        ...prevData[activeTab][key],
                        value: inputValue,
                    },
                },
            };
            return updatedData;
        });
    };

    const handleDelete = async () => {
        try {
            await axios.delete('/api/test/delete'); //TODO Adjust the endpoint as needed
            setIsDeleteDialogOpen(false);
            onClose("success", "deleted");
        } catch (error) {
            onClose("error", "delete failed");
        }
    };

    const handleOpenDeleteDialog = () => {
        setIsDeleteDialogOpen(true);
    };

    const handleCloseDeleteDialog = () => {
        setIsDeleteDialogOpen(false);
    };

    const tabKeys = Object.keys(data || {}).filter(key => key !== 'id');

    //console.log(activeTab, data)

    return (
        <>
            <Dialog
                open={isDeleteDialogOpen}
                onClose={handleCloseDeleteDialog}
            >
                <DialogTitle>{t`Delete Confirmation`}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t`Are you sure you want to delete this item?`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary">
                        {t`Cancel`}
                    </Button>
                    <Button onClick={handleDelete} color="secondary" variant="contained">
                        {t`Delete`}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Main dialog */}
            <Dialog
                open={open}
                maxWidth={false}
                fullWidth
                onClose={handleClose}
                PaperProps={{
                    sx: {
                        maxWidth: window.innerWidth > 1920 ? '70vw' : '90vw',
                        margin: 'auto',
                        maxHeight: "90vh",
                        p: 0,
                    }
                }}
            >
                <AppBar position="static">
                    <Tabs value={activeTab} onChange={handleTabChange}>
                        {tabKeys.filter(key => key !== 'globals' && key !== 'hiddenValues').map(key => (
                            <Tab
                                sx={{ color: "white" }}
                                key={key}
                                value={key}
                                label={data?.[key]?.navBarName || key}
                            />
                        ))}
                    </Tabs>
                </AppBar>
                <DialogContent sx={{
                    maxHeight: "85vh",
                    minHeight: "60vh",
                    overflowY: "auto",
                    overflowX: "hidden",
                    width: "100%",
                    minWidth: "100%",
                    p: 0,
                    pt: 2
                }}>
                    {isLoading && (
                        <Loader height={450} />
                    )}
                    {!pagesShouldNotBeMapped.includes(activeTab) && (
                        <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: "center", }} maxWidth={false}>
                            {Object.entries(data?.[activeTab] || {})
                                .filter(([key, item]: [string, any]) => {
                                    const isValidItem = (item: any) =>
                                        !(item.key === "" && item.name === "" && item.value === null);
                                    return item !== undefined && isValidItem(item);
                                })
                                .map(([key, item]: [string, any], index: number) => (
                                    key !== 'navBarName' &&
                                    key !== undefined && (
                                        <Grid size={12} key={key + index}>
                                            <LabeledTextField
                                                id={id}
                                                endpointUrl={item?.endpointUrl || undefined}
                                                label={item?.name || ""}
                                                placeholder={item?.placeholder || ""}
                                                options={item?.options || []}
                                                fieldType={item?.fieldType}
                                                value={item?.value}
                                                onValueChange={(event: any) => handleChange(event, key)}
                                                alternative={item?.alternative}
                                                urlType={item?.urlType}
                                            />
                                        </Grid>
                                    )
                                ))}
                        </Container>
                    )}

                    {pagesShouldNotBeMapped.includes(activeTab) && (
                        <>
                            {activeTab === "usage" && (<>
                                <UsageComponent id={id} activeTab={"customHeader"} />
                            </>)}

                            {activeTab === "customDimensionValues" && (
                                <DataTable<cCustomDimensionValueDetails>
                                    columns={dimensionValuesColumns()}
                                    data={data?.[activeTab] || []}
                                    noDataMessage={t`No data found`}
                                />
                            )}
                        </>
                    )}

                    {pagesShouldNotBeMapped.includes(activeTab) &&
                        (activeTab === "customHeaders" ||
                            activeTab === "customTags" ||
                            activeTab === "contexts" ||
                            activeTab === "customLabels"
                        ) && (
                            <>
                                <DataTableWithFetch
                                    id={id || ""}
                                    activeTab={activeTab || ""}
                                    endpoint={data?.id || ""}
                                    columns={
                                        getAlternativeColumnsMapping(activeTab)
                                    }
                                    data={data?.[activeTab] || []}
                                    noDataMessage={t`No data found`}
                                />
                            </>
                        )}

                    {activeTab === "dimensionLabels" && (
                        <>
                            <LabelTable
                                data={data?.dimensionLabels as cLabel[] || []}
                                languages={languages}
                                setData={(newData: selectTableProps[]) => {
                                    setData((prevData: dataMatchingToNavBar | null) => {
                                        if (!prevData) return prevData;
                                        const updatedData = {
                                            ...prevData,
                                            labels: newData,
                                        };
                                        return updatedData;
                                    });
                                }}
                            />
                        </>
                    )}

                    {pagesShouldNotBeMapped.includes(activeTab) && (
                        <>
                            {(activeTab === "extensionStructureReferences" ||
                                activeTab === "hierarchyElementReferences" ||
                                activeTab === "additionalElementReferences" ||
                                activeTab === "dimensionValueReferences" ||
                                activeTab === "childDimensionValues" ||
                                activeTab === "childElementReferences" ||
                                activeTab === "validators" ||
                                activeTab === "dimensionValueReferences"
                            ) && (
                                    <>
                                        <GenericGridTable
                                            type={activeTab}
                                            id={data?.id || ""}
                                            data={data?.[activeTab] || []}
                                            setData={(newData: expandedTaxonomyElement) => {
                                                setData((prevData: dataMatchingToNavBar | null) => {
                                                    if (!prevData) return prevData;
                                                    const updatedData = {
                                                        ...prevData,
                                                        [activeTab]: newData
                                                    };
                                                    return updatedData;
                                                });
                                            }}
                                        />
                                        {data?.globals && (
                                            <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: "center", p: 0, m: 0 }} maxWidth="xl" >
                                                {Object.entries(data.globals)
                                                    //.filter(([key, item]: [string, any]) => key === 'customTaxonomyGroup' && item !== undefined)
                                                    .filter(([key, item]: [string, any]) => {
                                                        const isValidItem = (item: any) =>
                                                            !(item.key === "" && item.name === "" && item.value === null);
                                                        return item !== undefined && isValidItem(item) && !(activeTab === "dimensionValueReferences" && key === "customTaxonomyGroup");
                                                    })
                                                    .map(([key, item]: [string, any], index: number) => (
                                                        <Grid size={12} key={key + index}>
                                                            <LabeledTextField
                                                                id={id}
                                                                endpointUrl={item?.endpointUrl || undefined}
                                                                label={item?.name || ""}
                                                                placeholder={item?.placeholder || ""}
                                                                options={item?.options || []}
                                                                fieldType={item?.fieldType}
                                                                value={item?.value}
                                                                onValueChange={(event: any) => handleChange(event, key)}
                                                                alternative={item?.alternative}
                                                            />
                                                        </Grid>
                                                    ))}
                                            </Container>
                                        )}
                                    </>

                                )}
                            {activeTab === "taxonomies" && (
                                <SelectTaxonomies
                                    isDialog={false}
                                    open={true}
                                    onClose={() => { }}
                                    onApply={handleTaxonomySetter}
                                />
                            )}
                        </>
                    )}
                </DialogContent>
                <Box display="flex" justifyContent="flex-end" m={3}>
                    <Container
                        maxWidth={"xl"}
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <Grid>
                            {id && (
                                <></>
                                /* 
                                <Button
                                    variant="contained"
                                    sx={{
                                        border: "1px solid #d32f2f",
                                        backgroundColor: "#d32f2f",
                                        ':hover': {
                                            backgroundColor: "#b71c1c"
                                        }
                                    }}
                                    onClick={handleOpenDeleteDialog}
                                >
                                    {t`Delete`}
                                </Button> 
                                */
                            )}
                        </Grid>
                        <Grid>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleClose}
                            >
                                {t`Close`}
                            </Button>
                            <Button sx={{ ml: 2 }}
                                variant="contained"
                                color="primary"
                                onClick={handleApply}
                            >
                                {t`Apply`}
                            </Button>
                        </Grid>
                    </Container>
                </Box>
            </Dialog >
        </>
    );
};

export default CustomObjectsDialog;
