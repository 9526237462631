export const mapDataToTemplate = (template: any, data: Record<string, any>, selectTemplate: string): any => {
    const mappedTemplate = JSON.parse(JSON.stringify(template));

    const updateTemplateField = (templateField: any, dataValue: any): void => {
        if (templateField) {
            templateField.value = dataValue;
        }
    };

    const traverseAndUpdate = (templateSection: any, data: Record<string, any>): void => {
        Object.entries(templateSection).forEach(([key, templateField]) => {
            if (templateField && typeof templateField === 'object') {
                if ('fieldType' in templateField) {
                    const dataValue = data[key as keyof typeof data];
                    if (dataValue !== undefined) {
                        updateTemplateField(templateField, dataValue);
                    }
                } else {
                    traverseAndUpdate(templateField, data);
                }
            }
        });
    };

    const addLangToLanguage = (templateSection: any, data: Record<string, any>): void => {
        Object.keys(templateSection).forEach((key) => {
            const templateField = templateSection[key];
            if (templateField && typeof templateField === 'object') {
                if (key === 'language' && 'lang' in data) {
                    templateField.value = data.lang;
                } else {
                    addLangToLanguage(templateField, data);
                }
            }
        });
    };

    const updateReferences = (key: string, navBarName: string): void => {
        if (key in data) {
            mappedTemplate[key] = data[key].map((reference: any) => ({ ...reference }));
            mappedTemplate[key].navBarName = navBarName;
        }
    };

    Object.keys(mappedTemplate).forEach((sectionKey) => {
        const section = mappedTemplate[sectionKey];
        if (section && typeof section === 'object') {
            traverseAndUpdate(section, data);
            addLangToLanguage(section, data);
        }
    });

    updateReferences('dimensionValueReferences', 'Dimension Values');
    updateReferences('additionalElementReferences', 'Additional Elements');
    updateReferences('extensionStructureReferences', 'Extension Structure');
    updateReferences('hierarchyElementReferences', 'Extension');
    updateReferences('childElementReferences', 'Child Elements');

    if ('labels' in data) {
        if (selectTemplate === "customDimensions" || selectTemplate === "dimensionValue" || selectTemplate === "extensionElements") {
            mappedTemplate.dimensionLabels = data.labels;
            mappedTemplate.dimensionLabels.navBarName = 'Dimension Labels';
        } else if (selectTemplate === "settings") {
            mappedTemplate.dimensionLabels = data.labels;
            mappedTemplate.dimensionLabels.navBarName = 'Labels';
        } else {
            mappedTemplate.customLabels = data.labels;
            mappedTemplate.customLabels.navBarName = 'Custom Labels';
        }
    }


    if ('taxonomies' in data) {
        mappedTemplate.taxonomies = data.taxonomies;
        mappedTemplate.taxonomies.navBarName = 'Taxonomies';
        localStorage.setItem('selectedTaxonomies', JSON.stringify(data.taxonomies));
    }

    return mappedTemplate;
};



export const mapTemplateToData = (
    template: any,
    id: string | null,
    type: string
): Record<string, any> => {
    const data: Record<string, any> = {};

    const capitalize = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1);

    const extractFieldValue = (field: any): any => {
        if (!field) return null;

        switch (field.fieldType) {
            case "dimension":
                return field.value?.customDimensionValue || field.value;
            case "element":
                return field.value?.taxonomyElement || field.value;
            default:
                return field.value ?? null;
        }
    };

    const processSection = (section: Record<string, any>, target: Record<string, any>): void => {
        Object.entries(section).forEach(([key, value]) => {
            if (value && typeof value === "object") {
                if ("fieldType" in value) {
                    target[capitalize(key)] = extractFieldValue(value);
                } else {
                    // Recursively process nested objects
                    processSection(value, target);
                }
            }
        });
    };

    const extractReferences = (
        key: string,
        source: Record<string, any>,
        target: Record<string, any>
    ): void => {
        const section = source[key];
        if (!section) return;

        const references = Array.isArray(section)
            ? section.map((ref: any) => (ref && typeof ref === "object" ? { ...ref } : null))
            : Object.values({ ...section, navBarName: undefined }).map((ref: any) =>
                ref && typeof ref === "object" ? { ...ref } : null
            );

        target[capitalize(key)] = references.filter((ref) => ref && Object.keys(ref).length > 0);
    };

    // Process main template structure
    Object.entries(template).forEach(([key, value]) => {
        if (value && typeof value === "object") {
            processSection(value, data);
        }
    });

    // Handle specific references
    [
        "dimensionValueReferences",
        "additionalElementReferences",
        "extensionStructureReferences",
        "hierarchyElementReferences",
        "elementReference",
    ].forEach((key) => extractReferences(key, template, data));

    // Handle dimension labels and taxonomies
    data.Labels = template.dimensionLabels || template.customLabels || null;
    if (template.taxonomies) data.Taxonomies = template.taxonomies;

    // Add ID if provided
    if (id) data.Id = id;

    // Add type-specific fields
    if (["labels", "Headers", "table-items"].includes(type)) {
        data.IsCustomHeader = type !== "labels";
        data.Type = {
            labels: "Row",
            Headers: "Header",
            "table-items": "TableItem",
        }[type];
    }

    // Clean up DimensionValueReferences
    if (Array.isArray(data.dimensionValueReferences)) {
        data.dimensionValueReferences = data.dimensionValueReferences.map((ref: any) =>
            ref?.taxonomyValue || ref?.customDimensionValue || ref
        );
    }

    // Clean up ElementReference
    if (Array.isArray(data.elementReference)) {
        data.elementReference = data.elementReference.map((ref: any) =>
            ref?.taxonomyElement || ref?.extension || ref
        );
    }

    console.log("Mapped Data:", data);
    return data;
};
