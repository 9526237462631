import { getCustomObjectTaxonomies } from "../api/taxonomies";
import { TaxonomyListProps } from "../api/types";

export const getTaxonomiesFromLocalStorage = (): TaxonomyListProps[] => {
    const storedTaxonomies = localStorage.getItem("customObjectsSelectedTaxonomies");
    return storedTaxonomies ? JSON.parse(storedTaxonomies) : [];
};

export const combineTaxonomies = (defaultTaxonomies: TaxonomyListProps[], storedTaxonomies: TaxonomyListProps[]): TaxonomyListProps[] => {
    const combinedTaxonomies = defaultTaxonomies.map(defaultTaxonomy => {
        const storedTaxonomy = storedTaxonomies.find(taxonomy => taxonomy.id === defaultTaxonomy.id);
        if (storedTaxonomy) {
            return { ...defaultTaxonomy, isSelected: storedTaxonomy.isSelected };
        }
        return defaultTaxonomy;
    });

    storedTaxonomies.forEach(storedTaxonomy => {
        if (!combinedTaxonomies.find(taxonomy => taxonomy.id === storedTaxonomy.id)) {
            combinedTaxonomies.push(storedTaxonomy);
        }
    });

    return combinedTaxonomies;
};

export const getAllTaxonomies = async () => {
    const response = await getCustomObjectTaxonomies();
    const defaultTaxonomies = response.data;
    const storedTaxonomies = getTaxonomiesFromLocalStorage();
    return combineTaxonomies(defaultTaxonomies, storedTaxonomies);
};

export const handleTaxonomiesChange = (changedTaxonomies: TaxonomyListProps[]): void => {
    const storedTaxonomies = localStorage.getItem("customObjectsSelectedTaxonomies");
    let taxonomies: TaxonomyListProps[] = storedTaxonomies ? JSON.parse(storedTaxonomies) : [];

    const updatedTaxonomies = changedTaxonomies.reduce((acc, changedTaxonomy) => {
        const existingTaxonomyIndex = acc.findIndex(taxonomy => taxonomy.id === changedTaxonomy.id);

        if (changedTaxonomy.isSelected) {
            if (existingTaxonomyIndex === -1) {
                acc.push(changedTaxonomy);
            } else {
                acc[existingTaxonomyIndex] = changedTaxonomy;
            }
        } else {
            if (existingTaxonomyIndex !== -1) {
                acc.splice(existingTaxonomyIndex, 1);
            }
        }
        return acc;
    }, taxonomies);

    localStorage.setItem("customObjectsSelectedTaxonomies", JSON.stringify(updatedTaxonomies));
};

export const getSelectedTaxonomyIds = (): string[] => {
    const storedTaxonomies = localStorage.getItem("customObjectsSelectedTaxonomies");
    if (!storedTaxonomies) {
        return [];
    }

    try {
        const parsedTaxonomies: TaxonomyListProps[] = JSON.parse(storedTaxonomies);
        if (!Array.isArray(parsedTaxonomies)) {
            console.error("Parsed taxonomies is not an array:", parsedTaxonomies);
            return [];
        }
        return parsedTaxonomies.filter(taxonomy => taxonomy.isSelected).map(taxonomy => taxonomy.id);
    } catch (error) {
        console.error("Error parsing storedTaxonomies:", error);
        return [];
    }
}
export const getTaxonomyNames = (taxonomyIds: string[]): string[] => {
    const storedTaxonomies = localStorage.getItem("customObjectsSelectedTaxonomies");

    if (!storedTaxonomies) {
        return [];
    }

    try {
        const parsedTaxonomies: TaxonomyListProps[] = JSON.parse(storedTaxonomies);
        if (!Array.isArray(parsedTaxonomies)) {
            console.error("Parsed taxonomies is not an array:", parsedTaxonomies);
            return [];
        }
        return parsedTaxonomies.filter(taxonomy => taxonomyIds.includes(taxonomy.id)).map(taxonomy => taxonomy.name);
    } catch (error) {
        console.error("Error parsing storedTaxonomies:", error);
        return [];
    }
}