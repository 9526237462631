import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    IconButton,
    TableHead,
    Typography,
    Tooltip,
    Paper,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { DimensionValue, expandedTaxonomyElement } from '../../api/types';
import { t } from '@lingui/macro';
import { DialogCustomDimensionValue } from './dialogCustomDimensionValue';
import { TABLE_CONFIG } from './customObjectsPreData';
import { useState } from 'react';

export type gridSelectionTypes = "basicList" | "extension" | "dimension" | "additionalElementReferences" |
    "extensionStructureReferences" | "onlyDimension" | "dimensionValueReferences" |
    "headerDimensionValues" | "labelDimensionValues" | "validators" |
    "childDimensionValues" | "settingsLabels" | "hierarchyElementReferences" | "childElementReferences" | "dimensionLabels";

export default function GenericGridTable({
    id,
    data,
    setData,
    type,
}: {
    id: string;
    data: expandedTaxonomyElement;
    setData: (newData: expandedTaxonomyElement) => void;
    type: gridSelectionTypes;
}) {
    const [showCaseData, setShowCaseData] = useState<{ [key: number]: DimensionValue }>(
        Object.fromEntries(
            Object.entries(data)
                .filter(([_, item]) => typeof item === 'object' && item !== null)
                .map(([key, item]) => [Number(key), item as DimensionValue])
        )
    );

    const [dialogState, setDialogState] = useState({
        open: false,
        currentDimensionValue: null as DimensionValue | null,
        isEditing: false,
    });

    const updateShowCaseData = (newData: { [key: number]: DimensionValue }) => {
        setShowCaseData(newData);
        setData({
            ...data,
            ...Object.fromEntries(Object.entries(newData).map(([key, value]) => [key, value])),
        });
    };

    const handleDialogClose = () => {
        setDialogState({ open: false, currentDimensionValue: null, isEditing: false });
    };

    const handleDialogOpen = (dimensionValue: DimensionValue | null, isEditing: boolean) => {
        setDialogState({ open: true, currentDimensionValue: dimensionValue, isEditing });
    };

    const handleAddOrEditDimensionValue = (dimensionValue: DimensionValue) => {
        let updatedData = { ...showCaseData };
        if (dialogState.isEditing && dialogState.currentDimensionValue) {
            const key = Object.keys(showCaseData).find(
                (k) => showCaseData[Number(k)] === dialogState.currentDimensionValue
            );
            if (key) {
                updatedData[Number(key)] = dimensionValue;
            }

        } else {
            const newKey = Object.keys(showCaseData).length;
            updatedData = {
                ...showCaseData,
                [newKey]: dimensionValue,
            };
        }
        updateShowCaseData(updatedData);
        handleDialogClose();
    };

    const deleteRow = (index: number) => {
        const updatedData = Object.values(showCaseData);
        updatedData.splice(index, 1);
        setShowCaseData(updatedData);
        setData({
            ...data,
            ...Object.fromEntries(updatedData.map((item, i) => [i, item])),
        });
    };

    const renderCells = (row: DimensionValue) =>
        columns.map((col) => (
            <TableCell key={col.key} align={col.header.align || 'left'}>
                {col.header.valueFormatter ? col.header.valueFormatter(row) : ""}
            </TableCell>
        ));

    const columns = TABLE_CONFIG()[type] || [];

    return (
        <TableContainer
            component={Paper}
            elevation={0}
            sx={{
                height: '55vh',
                maxWidth: '100%',
                overflow: 'auto',
                minHeight: '50vh',
            }}
        >
            <Table stickyHeader sx={{ minWidth: '100%' }}>
                <TableHead>
                    <TableRow>
                        {columns.map((col) => (
                            <TableCell key={col.header.id} align={col.header.align || 'left'}>
                                {col.header.label}
                            </TableCell>
                        ))}
                        <TableCell align="right">
                            <Tooltip title={t`Add Dimension Value`}>
                                <IconButton onClick={() => handleDialogOpen(null, false)}>
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.keys(showCaseData).length > 0 ? (
                        Object.values(showCaseData).map((row, index) => (
                            <TableRow key={index} sx={{ height: '50px' }}>
                                {renderCells(row)}
                                <TableCell align="right">
                                    <Tooltip title={t`Edit Row`}>
                                        <IconButton onClick={() => handleDialogOpen(row, true)}>
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <IconButton onClick={() => deleteRow(index)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={columns.length + 1} align="center">
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '50px',
                                    }}
                                >
                                    <Typography variant="body2" color="textSecondary">
                                        {t`No ${type.replace(/([A-Z])/g, ' $1').toLowerCase()} available`}
                                    </Typography>
                                </Box>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            {dialogState.open && (
                <DialogCustomDimensionValue
                    open={dialogState.open}
                    onClose={handleDialogClose}
                    onSave={handleAddOrEditDimensionValue}
                    type={type}
                    dimensionValueToEdit={dialogState.currentDimensionValue || undefined}
                />
            )}
        </TableContainer>
    );
}
