import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    TextField,
    Select,
    MenuItem,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { t } from "@lingui/macro";
import { cLabel } from "../api/types";
import EmptyTableAlert from "./emptyTableAlert";

interface LabelTableProps {
    data: cLabel[];
    setData: (labels: cLabel[]) => void;
    languages?: string[];
}

export default function LabelTable({ data, setData, languages }: LabelTableProps) {

    const handleInputChange = (index: number, field: keyof cLabel, value: string) => {
        const newData = [...data];
        newData[index][field] = value;
        setData(newData);
    };

    const handleAddRow = () => {
        setData([...data, { lang: "", text: "", role: "", id: " " }]);
    };

    const handleDeleteRow = (index: number) => {
        const newData = data.filter((_, i) => i !== index);
        setData(newData);
    };

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{t`Language`}</TableCell>
                        <TableCell>{t`Text`}</TableCell>
                        <TableCell align="right">
                            <IconButton onClick={handleAddRow} color="primary">
                                <AddIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.length === 0 && (
                        <TableRow>
                            <TableCell colSpan={3}>
                                <EmptyTableAlert
                                    title={t`No labels`}
                                    subTitle={t`There are no labels to display. Add a new label by clicking the button above.`}
                                    severity="info"
                                    showIcon={true}
                                />
                            </TableCell>
                        </TableRow>
                    )}
                    {data.length > 0 && languages && languages.length > 0 &&
                        data.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    <Select
                                        value={row.lang}
                                        onChange={(e) =>
                                            handleInputChange(index, "lang", e.target.value as string)
                                        }
                                        size="small"
                                        fullWidth
                                        sx={{
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                border: "none",
                                            },
                                            "& .MuiSelect-icon": {
                                                color: "black",
                                            },
                                        }}
                                    >
                                        {languages.map((lang) => (
                                            <MenuItem key={lang} value={lang}>
                                                {lang}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        sx={{
                                            width: "100%",
                                            height: "100%",
                                            p: 0,
                                            m: 0,
                                            "& .MuiOutlinedInput-root": {
                                                "& fieldset": {
                                                    border: "none",
                                                },
                                            },
                                        }}
                                        value={row.text}
                                        onChange={(e) =>
                                            handleInputChange(index, "text", e.target.value)
                                        }
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <IconButton
                                        onClick={() => handleDeleteRow(index)}
                                        color="secondary"
                                    >
                                        <DeleteIcon sx={{ color: "black" }} />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
