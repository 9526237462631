import { t } from "@lingui/macro"
import { ColumnProps, dataMatchingToNavBar, DimensionValue, ElementReference } from "../../api/types"
import { DataTableColumn } from "../dataTable";
import { getTaxonomyNames } from "../../helpers/taxonomyHelpers";

/*  Custom Object preDifined Data
------------------------------------------------------------------------ */
/*  Row Widths - These are used to set the width of the columns in the tables of the main custom object tables 
------------------------------------------------------------------------ */

const labelRowWidth = 200;
const elementRowWidth = 200;
const companyRowWidth = 100;
const tagGroupRowWidth = 100;
const dateSpectrumRowWidth = 150;
const languageRowWidth = 100;
const taxonomiesRowWidth = 100;
const dimensionValuesRowWidth = 100;
const additionalElementsRowWidth = 100;
const isLabelRowWidth = 150;
const smallNormalRowWidth = 100;

// - Api routes 

const AvaliableContexts = "api/custom-objects/contexts/available";
const AvaliableSettingCodes = "api/configuration/setting-codes";
const parserDataTypes = "api/configuration/parserTypes";

/*  CustomObject - dialog Tables 
------------------------------------------------------------------------ */
export const TABLE_CONFIG = (): Record<string, ColumnProps[]> => ({
    basicList: [
        {
            header: {
                id: "dimensionValue",
                label: t`Dimension Value`,
                align: 'left'
            },
            key: "dimensionValue",
            width: 200,
        }
    ],
    childElementReferences: [
        {
            header: {
                id: "element",
                label: t`Child Elements`,
                align: 'left',
                valueFormatter: (row: ElementReference) => row?.name
            },
            key: "element",
            width: 200,
        }
    ],
    dimensionLabels: [
        {
            header: {
                id: "lang",
                label: t`Language`,
                align: 'left',
                valueFormatter: (row) => row?.lang
            },
            key: "lang",
            width: 200,
        },
        {
            header: {
                id: "label",
                label: t`Label`,
                align: 'left',
                valueFormatter: (row) => row?.text
            },
            key: "label",
            width: 200,
        }
    ],
    dimensionValueReferences: [
        {
            header: {
                id: "dimensionValue",
                label: t`Dimension Value`,
                valueFormatter: (row: DimensionValue) => {
                    const hasTaxonomyvalue = row?.taxonomyValue?.dimensionValueReference;
                    const hasCustomDimensionValue = row?.customDimensionValue;
                    const hasDimensionValue = row?.dimensionValue;

                    const value = hasTaxonomyvalue ? `${hasTaxonomyvalue.namespaceAbbreviation}${hasTaxonomyvalue.name}` :
                        hasCustomDimensionValue ? `${hasCustomDimensionValue.dimension?.name}${hasCustomDimensionValue.dimensionName}` :
                            hasDimensionValue ? `${hasDimensionValue.taxonomyValue.dimensionReference.namespaceAbbreviation}${hasDimensionValue.valueName}` : "";

                    return value;
                }
            },
            key: "dimensionValue",
            width: 200,
        },
        {
            header: {
                id: "dimension",
                label: t`Dimension`,
                valueFormatter: (row: DimensionValue) => {
                    const hasTaxonomyvalue = row?.taxonomyValue?.dimensionValueReference;
                    const hasCustomDimensionValue = row?.customDimensionValue;
                    const hasDimensionValue = row?.dimensionValue;

                    const value = hasTaxonomyvalue ? `
                    ${hasTaxonomyvalue.namespaceAbbreviation}${hasTaxonomyvalue.name}` :
                        hasCustomDimensionValue ?
                            `${hasCustomDimensionValue.dimension?.name}${hasCustomDimensionValue.dimensionName}` :
                            hasDimensionValue ?
                                `${hasDimensionValue.taxonomyValue.dimensionReference.namespaceAbbreviation}${hasDimensionValue.taxonomyValue.dimensionReference.description}` : "";

                    return value;
                }
            },
            key: "dimension",
            width: 200,
        },
        {
            header: {
                id: "typedMemberValue",
                label: t`Typed Member Value`,
                valueFormatter: (row: DimensionValue) => (row?.isCustom ? "true" : ""),
            },
            key: "typedMemberValue",
            width: 200,
        },
        {
            header: {
                id: "consolidated",
                label: t`Consolidated`,
                valueFormatter: (row: DimensionValue) => (row?.isCustom ? "true" : ""),
            },
            key: "consolidated",
            width: 200,
        }
    ],
    labelDimensionValues: [
        {
            header: {
                id: "language",
                label: t`Language`,
                valueFormatter: (row) => row?.language,
            },
            key: "language",
            width: 200,
        },
        {
            header: {
                id: "label",
                label: t`Label`,
                valueFormatter: (row) => row?.label,
            },
            key: "label",
            width: 200,
        }
    ],
    contextDimensionValues: [
        {
            header: {
                id: "dimensionName",
                label: t`Dimension Name`,
                valueFormatter: (row) => row?.name,
            },
            key: "dimensionName",
            width: 200,
        },
        {
            header: {
                id: "dimension",
                label: t`Dimension`,
                valueFormatter: (row) => row?.dimensionName,
            },
            key: "dimension",
            width: 200,
        },
        {
            header: {
                id: "consolidated",
                label: t`Consolidated`
            },
            key: "consolidated",
            width: 200,
        }
    ],
    tagsDimensionValues: [
        {
            header: {
                id: "dimensionValue",
                label: t`Dimension Value`
            },
            key: "dimensionValue",
            width: 200,
        },
        {
            header: {
                id: "dimension",
                label: t`Dimension`
            },
            key: "dimension",
            width: 200,
        },
        {
            header: {
                id: "consolidated",
                label: t`Consolidated`
            },
            key: "consolidated",
            width: 200,
        }
    ],
    headerDimensionValues: [
        {
            header: {
                id: "dimensionName",
                label: t`Dimension Name`
            },
            key: "dimensionName",
            width: 200,
        },
        {
            header: {
                id: "dimension",
                label: t`Dimension`
            },
            key: "dimension",
            width: 200,
        },
        {
            header: {
                id: "typedMemberValue",
                label: t`Typed Member Value`
            },
            key: "typedMemberValue",
            width: 200,
        },
        {
            header: {
                id: "consolidated",
                label: t`Consolidated`
            },
            key: "consolidated",
            width: 200,
        }
    ],
    childDimensionValues: [
        {
            header: {
                id: "dimensionValue",
                label: t`Dimension Value`
            },
            key: "dimensionValue",
            width: 200,
        }
    ],
    additionalElementReferences: [
        {
            header: {
                id: "additionalElements",
                label: t`Additional Elements`,
                valueFormatter: (row) => row?.name,
            },
            key: "additionalElements",
            width: 200,
        }
    ],
    extension: [
        {
            header: {
                id: "structure",
                label: t`Structure`
            },
            key: "structure",
            width: 200,
        }
    ],
    extensionStructureReferences: [
        {
            header: {
                id: "structure",
                label: t`Structure`,
                valueFormatter: (row) => row?.name,
            },
            key: "structure",
            width: 200,
        }
    ],
    hierarchyElementReferences: [
        {
            header: {
                id: "hierarchy",
                label: t`Hierarchy`,
                valueFormatter: (row) => row?.name,
            },
            key: "hierarchy",
            width: 200,
        }
    ],
    onlyDimension: [
        {
            header: {
                id: "dimension",
                label: t`Dimension`
            },
            key: "dimension",
            width: 200,
        }
    ],
    settingsLabels: [
        {
            header: {
                id: "label",
                label: t`Label`,
                valueFormatter: (row) => row?.text,
            },
            key: "label",
            width: 200,
        },
        {
            header: {
                id: "language",
                label: t`Language`,
                valueFormatter: (row) => row?.lang,
            },
            key: "language",
            width: 200,
        }
    ]
});

/*  Custom Object Templates 
------------------------------------------------------------------------ */
/* Templates are used to create the structure of the custom object dialog depending on the type of object being created or edited.   
------------------------------------------------------------------------ */

export const tagsTemplate: () => dataMatchingToNavBar = () => {
    return {
        id: "tags",
        details: {
            navBarName: t`Details`,
            label: { key: "label", name: t`label`, fieldType: "text", value: null },
            company: { key: "company", name: t`Company`, fieldType: "company", value: null, },
            requestGlobal: { key: "requestGlobal", name: t`Request Global`, fieldType: "checkbox", value: false },
            tagGroup: {
                key: "tagGroup", name: t`Tag Group`, fieldType: "tagGroup", value: null,
            },
            dateSpectrum: { key: "dateSpectrum", name: t`Date Spectrum`, fieldType: "dateSpectrum", value: null, },
            isLabel: { key: "isLabel", name: t`Is Label`, fieldType: "checkbox", value: false },
            isSetting: { key: "isSetting", name: t`Is Setting`, fieldType: "checkbox", value: false },
            mergePresentation: { key: "mergePresentation", name: t`Merge Presentation`, fieldType: "checkbox", value: false },
            isFootnote: { key: "isFootnote", name: t`Is Footnote`, fieldType: "checkbox", value: false, },
            isArea: { key: "isArea", name: t`Is Area`, fieldType: "checkbox", value: false, },
            language: { key: "language", name: t`Language`, fieldType: "languages", value: null, },
            context: { key: "context", name: t`Context`, fieldType: "endpoint", value: null, endpointUrl: AvaliableContexts },
        },
        tableTag: {
            navBarName: t`Table Tag`,
            isTableTag: { key: "isTableTag", name: t`Is Table Tag`, fieldType: "checkbox", value: false },
            isSetting: { key: "isSetting", name: t`Is Setting`, fieldType: "checkbox", value: false },
            strictParsing: { key: "strictParsing", name: t`Strict Parsing`, fieldType: "checkbox", value: false },
        },
        dimensionValueReferences: {
            navBarName: t`Dimension Values`,
        },
        hierarchyElementReferences: {
            navBarName: t`Extension`,
        },
        extensionStructureReferences: {
            navBarName: t`Extension Structure`,
        },
        taxonomies: [],
        usage: {
            navBarName: t`Usage`,
        },
        globals: {
            customTaxonomyGroup: { key: "customTaxonomyGroup", name: t`Custom Taxonomy Group`, fieldType: "text", value: null },
        },
        hiddenValues: {
            type: { key: "type", value: null },
            table: { key: "tableItem", value: null },
            row: { key: "row", value: null },
        }
    }
}

export const headerTemplate: () => dataMatchingToNavBar = () => {
    return {
        id: "header",
        details: {
            navBarName: t`Details`,
            label: { key: "label", name: t`Label`, fieldType: "text", value: null, },
            company: { key: "company", name: t`Company`, fieldType: "company", value: null, },
            requestGlobal: { key: "requestGlobal", name: t`Request Global`, fieldType: "checkbox", value: false },
            element: { key: "element", name: t`Element`, fieldType: "element", value: null, },
            tagGroup: { key: "tagGroup", name: t`Tag Group`, fieldType: "tagGroup", value: null, },
            dateSpectrum: { key: "dateSpectrum", name: t`Date Spectrum`, fieldType: "dateSpectrum", value: null },
            invertSign: { key: "invertSign", name: t`Invert Sign`, fieldType: "checkbox", value: false },
            skipTagging: { key: "skipTagging", name: t`Skip Tagging`, fieldType: "checkbox", value: false },
            isLabelFootnote: { key: "isLabelFootnote", name: t`Is Label Footnote`, fieldType: "checkbox", value: false },
            language: { key: "language", name: t`Language`, fieldType: "searchSelect", value: null, },
            ignoreInPresentation: { key: "ignoreInPresentation", name: t`Ignore In Presentation`, fieldType: "checkbox", value: false },
        },
        overrides: {
            navBarName: t`Overrides`,
            context: { key: "context", name: t`Context`, fieldType: "endpoint", value: null, endpointUrl: AvaliableContexts, },
            currency: { key: "currency", name: t`Currency`, fieldType: "text", value: null, },
            scale: { key: "scale", name: t`Scale`, fieldType: "text", value: null, },
            valueLanguage: { key: "valueLanguage", name: t`Value Language`, fieldType: "languages", value: null, },
            dateOverride: { key: "dateOverride", name: t`Date Override`, fieldType: "text", value: null, placeholder: "" },
        },
        searchHint: {
            navBarName: t`Search hint`,
            searchDirection: { key: "searchDirection", name: t`Search direction`, fieldType: "dateSpectrum", value: null, },
        },
        dimensionValueReferences: {
            navBarName: t`Dimension Values`,
        },
        tuple: {
            navBarName: t`Tuple`,
            isTuple: { key: "isTuple", name: t`Is Tuple`, fieldType: "checkbox", value: false },
            element: { key: "element", name: t`Element`, fieldType: "element", value: null, },
        },
        taxonomies: [],
        usage: {
            navBarName: t`Usage`,
        },
        globals: {
            overrideTypedMemeber: { key: "overrideTypedMemeber", name: t`Override Typed Memeber`, fieldType: "dimension", value: null },
        },
        hiddenValues: {
            type: { key: "type", value: null },
            table: { key: "tableItem", value: null },
            row: { key: "row", value: null },
        }
    };
};

export const labelsTemplate: () => dataMatchingToNavBar = () => {
    return {
        id: "labels",
        details: {
            navBarName: t`Details`,
            label: { key: "label", name: t`Label`, fieldType: "text", value: null },
            company: { key: "company", name: t`Company`, fieldType: "company", value: null, },
            requestGlobal: { key: "requestGlobal", name: t`Request Global`, fieldType: "checkbox", value: false },
            elementReference: { key: "elementReference", name: t`Element`, fieldType: "element", value: null, },
            nameElement: { key: "nameElement", name: t`Name Element`, fieldType: "element", value: null },
            tagGroup: {
                key: "tagGroup", name: t`Tag Group`, fieldType: "tagGroup", value: null,
            },
            dateSpectrum: {
                key: "dateSpectrum", name: t`Date Spectrum`, fieldType: "dateSpectrum", value: null,
            },
            invertSign: { key: "invertSign", name: t`Invert Sign`, fieldType: "checkbox", value: false },
            lang: { key: "lang", name: t`Language`, fieldType: "languages", value: null, },
            ignoreInPresentation: { key: "ignoreInPresentation", name: t`Ignore In Presentation`, fieldType: "checkbox", value: false },
        },
        overrides: {
            navBarName: t`Overrides`,
            context: { key: "context", name: t`Context`, fieldType: "endpoint", value: null, endpointUrl: AvaliableContexts, },
            currency: { key: "currency", name: t`Currency`, fieldType: "text", value: null, },
            scale: { key: "scale", name: t`Scale`, fieldType: "text", value: null, },
            valueLanguage: { key: "valueLanguage", name: t`Value Language`, fieldType: "languages", value: null, },
            transformation: { key: "transformation", name: t`Transformation`, fieldType: "text", value: null },
            before: { key: "before", name: t`Before`, fieldType: "text", value: null },
            merge: { key: "merge", name: t`Merge`, fieldType: "alternateCheckbox", value: null, alternative: true },
            allowHTML: { key: "allowHTML", name: t`Allow HTML`, fieldType: "alternateCheckbox", value: null, alternative: true },
            skipSpace: { key: "skipSpace", name: t`Skip Space`, fieldType: "alternateCheckbox", value: null, alternative: true },
            dateOverride: { key: "dateOverride", name: t`Date Override`, fieldType: "text", value: null },
        },
        dimensionValueReferences: {
            navBarName: t`Dimension Value`,
        },
        additionalElementReferences: {
            navBarName: t`Additional Elements`,
        },
        taxonomies: [],
        tuple: {
            navBarName: t`Tuple`,
            isTuple: { key: "isTuple", name: t`Is Tuple`, fieldType: "checkbox", value: false },
            element: { key: "element", name: t`Element`, fieldType: "element", value: null, },
        },
        usage: {
            navBarName: t`Usage`,
        },
        globals: {
            typedMemberValue: { key: "typedMemberValue", name: t`Override Typed Member`, fieldType: "dimension", value: null },
        },
        hiddenValues: {
            id: { key: "id", value: null },
            type: { key: "type", value: null },
            table: { key: "tableItem", value: null },
            row: { key: "row", value: null },
        }
    };
};

export const customDimensionsTemplate: () => dataMatchingToNavBar = () => {
    return {
        id: "dimensions",
        details: {
            navBarName: t`Dimension Value`,
            customDimensionName: { key: "customDimensionName", name: t`Custom Dimension Name`, fieldType: "text", value: null },
            company: { key: "company", name: t`Company`, fieldType: "company", value: null, },
            requestGlobal: { key: "requestGlobal", name: t`Request Global`, fieldType: "checkbox", value: false }
        },
        dimensionLabels: {
            navBarName: t`Dimension Labels`,
        },
        customLabels: {
            navBarName: t`Custom Labels`,
        },
        customHeaders: {
            navBarName: t`Custom Headers`,
        },
        customTags: {
            navBarName: t`Custom Tags`,
        },
        contexts: {},
        taxonomies: [],
        usage: {
            navBarName: t`Usage`,
        }
    };
}

export const dimensionValueTemplate: () => dataMatchingToNavBar = () => {
    return {
        id: "dimensionValue",
        details: {
            navBarName: t`Dimension Value`,
            element: { key: "customMemberName", name: t`Dimension`, fieldType: "dimension", value: null, },
            customValue: { key: "customValue", name: t`Custom Value`, fieldType: "text", value: null },
            company: { key: "company", name: t`Company`, fieldType: "company", value: null, },
            requestGlobal: { key: "requestGlobal", name: t`Request Global`, fieldType: "checkbox", value: false },
            isTypedMember: { key: "isTypedMember", name: t`Is Typed Member`, fieldType: "checkbox", value: false },
            isDefaultMember: { key: "isDefaultMember", name: t`Is Default Member`, fieldType: "checkbox", value: false },
            parentMember: { key: "parentMember", name: t`Parent Member`, fieldType: "text", value: null, disabled: true }
        },
        dimensionLabels: {
            navBarName: t`Dimension Labels`,
        },
        taxonomies: [],
        valueLabels: {
            navBarName: t`Value Labels`,
        },
        customLabels: {
            navBarName: t`Custom Labels`,
        },
        customHeaders: {
            navBarName: t`Custom Headers`,
        },
        customTags: {
            navBarName: t`Custom Tags`,
        },
        contexts: {
            navBarName: t`Contexts`,
        },
        customDimensionValues: {
            navBarName: t`Custom Dimension Values`,
        },
    };
}


export const extensionElementsTemplate: () => dataMatchingToNavBar = () => {
    return {
        id: "extensionElements",
        details: {
            navBarName: t`Details`,
            element: { key: "element", name: t`Element`, fieldType: "text", value: null, },
            company: { key: "company", name: t`Company`, fieldType: "company", value: null, },
            requestGlobal: { key: "requestGlobal", name: t`Request Global`, fieldType: "checkbox", value: false },
            elementDataType: { key: "elementDataType", name: t`Element Data Type`, fieldType: "endpoint", value: null, endpointUrl: parserDataTypes },
            isInstant: { key: "isInstant", name: t`Is Instant`, fieldType: "checkbox", value: false },
            invertSign: { key: "invertSign", name: t`Invert Sign`, fieldType: "checkbox", value: false },
            isCredit: { key: "isCredit", name: t`Is Credit`, fieldType: "alternateCheckbox", value: false },
            isAbstract: { key: "isAbstract", name: t`Is Abstract`, fieldType: "checkbox", value: null },
            parent: { key: "parent", name: t`Parent`, fieldType: "element", value: null }
        },
        childElementReferences: {
            navBarName: t`Child Elementss`,
        },
        labels: {
            navBarName: t`Labels`,
        },
        documentation: {
            navBarName: t`Documentation`,
        },
        customLabels: {
            navBarName: t`Custom Labels`,
        },
        customHeaders: {
            navBarName: t`Custom Headers`,
        },
        taxonomies: [],
        usage: {
            navBarName: t`Usage`,
        },
    };
}

export const settingsTemplate: () => dataMatchingToNavBar = () => {
    return {
        id: "settings",
        details: {
            navBarName: t`Details`,
            settingName: { key: "SettingName", name: t`Name`, fieldType: "endpoint", endpointUrl: AvaliableSettingCodes, urlType: "GET", value: null, },
            required: { key: "required", name: t`Required`, fieldType: "checkbox", value: false }
        },
        dimensionLabels: {
            navBarName: t`Labels`,
        },
        taxonomies: [],
    };
}


export const ContextSettingsTemplate: () => dataMatchingToNavBar = () => {
    return {
        id: "contextSettings",
        details: {
            navBarName: t`Details`,
            context: { key: "context", name: t`Context`, fieldType: "text", value: null, endpointUrl: AvaliableContexts },
            period: { key: "period", name: t`Period`, fieldType: "endpoint", value: null, endpointUrl: AvaliableSettingCodes, urlType: "GET", }
        },
        dimensionValueReferences: {
            navBarName: t`Dimension Values`,
        },
        taxonomies: [],
    };
}

/*  Taxonomy Templates for dialog 
------------------------------------------------------------------------ */

export const taxonomyElementTemplate: () => dataMatchingToNavBar = () => {
    return {
        id: "taxonomyElement",
        details: {
            navBarName: t`Details`,
            label: { key: "name", name: t`Name`, fieldType: "text", value: null, disabled: true },
            nameSpace: { key: "nameSpace", name: t`Name Space`, fieldType: "text", value: null, disabled: true },
            periodType: { key: "periodType", name: t`Period Type`, fieldType: "select", value: null, disabled: true },
            taxonomyDataType: { key: "taxonomyDataType", name: t`Taxonomy Data Type`, fieldType: "text", value: null, disabled: true },
            parserDataType: { key: "parserDataType", name: t`Parser Data Type`, fieldType: "select", value: null },
            isAbstract: { key: "isAbstract", name: t`Is Abstract`, fieldType: "text", value: null, disabled: true },
            isCredit: { key: "isCredit", name: t`Is Credit`, fieldType: "checkbox", value: false, },
            invertSign: { key: "invertSign", name: t`Invert Sign`, fieldType: "checkbox", value: false, },
            rawNumber: { key: "rawNumber", name: t`Raw Number`, fieldType: "checkbox", value: false, },
        },
        validators: {
            navBarName: t`Validators`,
            dataTable: {
                data: [], // table - code - parameter - data - error message (user can add)
            }
        },
        labels: {
            navBarName: t`Labels`,
            dataTable: {
                data: [], // label - Role - Language
            }
        },
        customLabels: {
            navBarName: t`Custom Labels`,
            dataTable: {
                data: [], // label - element - company - tag group - date spectrum - language - taxonomies - dimensionValues - additionalElements (user can add)
            }
        },
        usage: {
            navBarName: t`Usage`,
        },
    };
}

/*  Custom Object Table Columns 
------------------------------------------------------------------------ */

export const ContextColumns: <T>() => DataTableColumn<T>[] = () => {
    return [
        {
            header: {
                id: "context",
                label: t`Context`,
            },
            key: "context",
            width: 200,
        },
        {
            header: {
                id: "period",
                label: t`Period`,
            },
            key: "period",
            width: 200,
        },
        {
            header: {
                id: "taxonomies",
                label: t`Taxonomies`,
            },
            key: "taxonomies",
            width: 200,
        },
        {
            header: {
                id: "dimensionValues",
                label: t`Dimension Values`,
            },
            key: "dimensionValues",
            width: 200,
        },
    ];
}

export const Tagscolumns: <T>() => DataTableColumn<T>[] = () => {
    return [
        {
            header: {
                id: "label",
                label: t`Label`,
                optionsHeaderText: t`Label`,
                shouldBeFiltered: true,
                inputType: "text",
                width: labelRowWidth
            },
            key: "label",
            shouldWrapVertically: true,
            width: labelRowWidth,
        },
        {
            header: {
                id: "tagGroup",
                label: t`Tag Group`,
                optionsHeaderText: t`Select Tag Group`,
                inputType: "select",
                width: tagGroupRowWidth,
                shouldBeFiltered: true,
                shouldWrapVertically: true,
            },
            key: "tagGroup",
            shouldWrapVertically: true,
            width: tagGroupRowWidth,
        },
        {
            header: {
                id: "company_name",
                label: t`Company`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`Company`,
                width: companyRowWidth,
            },
            valueFormatter: (row: any) => {
                return renderCompany(row);
            },
            key: "company",
            width: companyRowWidth,
        },
        {
            header: {
                id: "dateSpectrum",
                label: t`Date Spectrum`,
                shouldBeFiltered: true,
                optionsHeaderText: t`Select Date Spectrum`,
                inputType: "select",
                width: dateSpectrumRowWidth,
            },
            key: "dateSpectrum",
            width: dateSpectrumRowWidth,
        },
        {
            header: {
                id: "isLabel",
                label: t`Is Label`,
                shouldBeFiltered: true,
                inputType: "select",
                optionsHeaderText: t`Select Is Label`,
                width: isLabelRowWidth,
            },
            key: "isLabel",
            width: isLabelRowWidth,
        },
        {
            header: {
                id: "taxonomies",
                label: t`Taxonomies`,
                shouldBeFiltered: false,
                optionsHeaderText: t`Select Taxonomy`,
                inputType: "select",
                width: taxonomiesRowWidth,
            },
            valueFormatter: (row: any) => {
                return renderTaxonomies(row);
            },
            key: "taxonomies",
            width: taxonomiesRowWidth,
            shouldWrapVertically: true,
        },
        {
            header: {
                id: "dimensionValues",
                label: t`Dimension Values`,
                shouldBeFiltered: false,
                width: dimensionValuesRowWidth,
            },
            valueFormatter: (row: any) => {
                return renderDimensionValues(row);
            },
            key: "dimensionValues",
            width: dimensionValuesRowWidth,
            shouldWrapVertically: true,
        },
    ];
};

export const TagsAltColumns: <T>() => DataTableColumn<T>[] = () => {
    return [
        {
            header: {
                id: "label",
                label: t`Label`,
                optionsHeaderText: t`Label`,
                shouldBeFiltered: true,
                inputType: "text",
            },
            key: "label",
            width: labelRowWidth,
        },
        {
            header: {
                id: "company_name",
                label: t`Company`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`Company`,
                width: 200,
            },
            valueFormatter: (row: any) => {
                return renderCompany(row);
            },
            key: "company",
            width: 200,
        },
        {
            header: {
                id: "tagGroup",
                label: t`Tag Groups`,
                shouldBeFiltered: true,
                optionsHeaderText: t`Select Tag Group`,
                inputType: "select",
            },
            key: "tagGroup",
            width: 100,
        },
        {
            header: {
                id: "dateSpectrum",
                label: t`Date Spectrum`,
                shouldBeFiltered: true,
                optionsHeaderText: t`Select Date Spectrum`,
                inputType: "select",
            },
            key: "dateSpectrum",
            width: 100,
        },
        {
            header: {
                id: "isLabel",
                label: t`Is Label`,
                shouldBeFiltered: true,
                inputType: "select",
                optionsHeaderText: t`Select Is Label`,
                options: ["true", "false"],
            },
            key: "isLabel",
            width: 50,
        },
    ];
};

export const HeadersColumns: <T>() => DataTableColumn<T>[] = () => {
    return [
        {
            header: {
                id: "label",
                label: t`Label`,
                shouldBeFiltered: true,
                inputType: "text",
                width: labelRowWidth,
                optionsHeaderText: t`Label`,
            },
            shouldWrapVertically: true,
            key: "label",
            width: labelRowWidth,
            valueFormatter: (row: any) => {
                return renderisSafeLabel(row);
            },
        },
        {
            header: {
                id: "elementReference_name",
                label: t`Element`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`Element`,
                width: elementRowWidth,
            },
            valueFormatter: (row: any) => {
                return renderElement(row);
            },
            key: "elementReference_name",
            shouldWrapVertically: true,
            width: elementRowWidth,
        },
        {
            header: {
                id: "company_name",
                label: t`Company`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`Company`,
                width: companyRowWidth,
            },
            valueFormatter: (row: any) => {
                return renderCompany(row);
            },
            key: "company",
            width: companyRowWidth,
        },
        {
            header: {
                id: "tagGroup",
                label: t`Tag Group`,
                optionsHeaderText: t`Select Tag Group`,
                inputType: "select",
                width: tagGroupRowWidth,
                shouldBeFiltered: true,
                shouldWrapVertically: true,
            },
            key: "tagGroup",
            shouldWrapVertically: true,
            width: tagGroupRowWidth,
        },
        {
            header: {
                id: "dateSpectrum",
                label: t`Date Spectrum`,
                optionsHeaderText: t`Select Date Spectrum`,
                width: dateSpectrumRowWidth,
                inputType: "select",
                shouldBeFiltered: true,
                shouldWrapVertically: true,
            },
            key: "dateSpectrum",
            shouldWrapVertically: true,
            width: dateSpectrumRowWidth,
        },
        {
            header: {
                id: "language",
                label: t`Language`,
                optionsHeaderText: t`Select Language`,
                shouldBeFiltered: true,
                inputType: "select",
                width: languageRowWidth,
            },
            key: "language",
            shouldWrapVertically: true,
            width: languageRowWidth,
        },
        {
            header: {
                id: "taxonomies",
                label: t`Taxonomies`,
                width: taxonomiesRowWidth,
            },
            valueFormatter: (row: any) => {
                return renderTaxonomies(row);
            },
            key: "taxonomies",
            shouldWrapVertically: true,
            width: taxonomiesRowWidth,
        },
        {
            header: {
                id: "dimensionValueReferences_dimensionValue_name",
                width: dimensionValuesRowWidth,
                label: t`Dimension Values`,
                shouldBeFiltered: true,
                inputType: "text",
            },
            valueFormatter: (row: any) => {
                return renderDimensionValues(row);
            },
            key: "dimensionValueReferences_dimensionValue_name",
            shouldWrapVertically: true,
            width: dimensionValuesRowWidth,
        },
        {
            header: {
                id: "additionalElements",
                label: t`Additional Elements`,
                width: additionalElementsRowWidth,
            },
            valueFormatter: (row: any) => {
                return renderAdditionalElements(row);
            },
            key: "additionalElements",
            shouldWrapVertically: true,
            width: additionalElementsRowWidth,
        }
    ];
}

export const labelsColumns: <T>() => DataTableColumn<T>[] = () => {
    return HeadersColumns();
}

export const customDimensionColumns: <T>() => DataTableColumn<T>[] = () => {
    return [
        {
            header: {
                id: "name",
                label: t`Dimension`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`Dimension`,
            },
            valueFormatter: (row: any) => {
                return row.name || "";
            },
            key: "name",
            width: 400,
        },
        {
            header: {
                id: "company_name",
                label: t`Company`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`Company`,
            },
            valueFormatter: (row: any) => {
                return renderCompany(row);
            },
            key: "company",
            width: companyRowWidth,
        },
        {
            header: {
                id: "taxonomies",
                label: t`Taxonomies`,
            },
            valueFormatter: (row: any) => {
                return renderTaxonomies(row);
            },
            key: "taxonomies",
            width: taxonomiesRowWidth,
        },
    ]
}

export const dimensionValuesColumns: <T>() => DataTableColumn<T>[] = () => {
    return [
        {
            header: {
                id: "dimension",
                label: t`Dimension`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`dimension`,
            },
            valueFormatter: (row: any) => {
                return renderDimension(row);
            },
            shouldWrapVertically: true,
            key: "dimension",
            width: dimensionValuesRowWidth,
        },
        {
            header: {
                id: "value",
                label: t`Value`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`Value`,
            },
            valueFormatter: (row: any) => {
                return row.customMemberName || row.MemberName;
            },
            shouldWrapVertically: true,
            key: "value",
            width: 200,
        },
        {
            header: {
                id: "isTyped",
                label: t`Is Typed`,
            },
            valueFormatter: (row: any) => {
                return row.isTypedMember ? "Yes" : "No";
            },
            key: "isTyped",
            width: smallNormalRowWidth,
        },
        {
            header: {
                id: "isDefault",
                label: t`Is Default`,
            },
            valueFormatter: (row: any) => {
                return row.isDefaultMemeber ? "Yes" : "No";
            },
            key: "isDefault",
            width: smallNormalRowWidth,
        },
        {
            header: {
                id: "company_name",
                label: t`Company`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`Company`,
            },
            valueFormatter: (row: any) => {
                return renderCompany(row);
            },
            key: "company",
            width: companyRowWidth,
        },
        {
            header: {
                id: "taxonomies",
                label: t`Taxonomies`,
            },
            valueFormatter: (row: any) => {
                return renderTaxonomies(row);
            },
            key: "taxonomies",
            width: taxonomiesRowWidth,
        },
        {
            header: {
                id: "wider",
                label: t`Wider`,
            },
            valueFormatter: (row: any) => {
                return renderWider(row);
            },
            shouldWrapVertically: true,
            key: "wider",
            width: 200,
        },
        {
            header: {
                id: "narrower",
                label: t`Narrower`,
            },
            key: "narrower",
            width: 200,
        },
    ]
}

export const extensionElementsColumns: <T>() => DataTableColumn<T>[] = () => {
    return [
        {
            header: {
                id: "name",
                label: t`Element Name`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`Element Name`,
            },
            shouldWrapVertically: true,
            key: "name",
            width: 300,
        },
        {
            header: {
                id: "company_name",
                label: t`Company`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`Company`,
            },
            valueFormatter: (row: any) => {
                return renderCompany(row);
            },
            shouldWrapVertically: true,
            key: "company",
            width: companyRowWidth,
        },
        {
            header: {
                id: "taxonomies",
                label: t`Taxonomies`,
            },
            valueFormatter: (row: any) => {
                return renderTaxonomies(row);
            },
            shouldWrapVertically: true,
            key: "taxonomies",
            width: taxonomiesRowWidth,
        },
        {
            header: {
                id: "parentReference_name",
                label: t`Wider`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`Wider`,
            },
            valueFormatter: (row: any) => {
                return renderWider(row);
            },
            shouldWrapVertically: true,
            key: "parentReference_name",
            width: 200,
        },
        {
            header: {
                id: "narrower",
                label: t`Narrower`,
            },
            key: "narrower",
            width: 150,
        },
    ]
}

export const settingsColumns: <T>() => DataTableColumn<T>[] = () => {
    return [
        {
            header: {
                id: "settingName",
                label: t`Setting`,
                shouldBeFiltered: true,
                inputType: "select",
                optionsHeaderText: t`Select Setting`,
            },
            key: "settingName",
            width: 150,
        },
        {
            header: {
                id: "required",
                label: t`Required`,
                shouldBeFiltered: true,
                inputType: "select",
                optionsHeaderText: t`Select Required`,
            },
            key: "required",
            width: smallNormalRowWidth,
        },
        {
            header: {
                id: "Labels",
                label: t`Labels`,
                shouldBeFiltered: false,
                optionsHeaderText: t`Labels`,
            },
            valueFormatter: (row: any) => {
                return renderWider(row);
            },
            shouldWrapVertically: true,
            key: "Labels",
            width: labelRowWidth,
        },
        {
            header: {
                id: "taxonomies",
                label: t`Taxonomies`,
            },
            valueFormatter: (row: any) => {
                return renderTaxonomies(row);
            },
            key: "taxonomies",
            width: taxonomiesRowWidth
        },
    ]
}

export const contextSettingsColumns: <T>() => DataTableColumn<T>[] = () => {
    return [
        {
            header: {
                id: "context",
                label: t`Context`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`Context`,
            },
            key: "context",
            width: 200,
        },
        {
            header: {
                id: "period",
                label: t`Period`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`Period`,
            },
            key: "period",
            width: smallNormalRowWidth,
        },
        {
            header: {
                id: "taxonomies",
                label: t`Taxonomies`,
            },
            valueFormatter: (row: any) => {
                return renderTaxonomies(row);
            },
            key: "taxonomies",
            width: taxonomiesRowWidth,
        },
        {
            header: {
                id: "dimensionValues",
                label: t`Dimension Values`,
            },
            valueFormatter: (row: any) => {
                return renderDimensionValues(row);
            },
            key: "dimensionValues",
            width: dimensionValuesRowWidth,
        },
    ]
}

export const globalRequestColumns: <T>() => DataTableColumn<T>[] = () => {
    return [
        {
            header: {
                id: "label",
                label: t`Label`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`Label`,
            },
            key: "label",
            width: labelRowWidth,
        },
        {
            header: {
                id: "entity",
                label: t`Entity`,
                shouldBeFiltered: true,
                inputType: "select",
                optionsHeaderText: t`Select Entity`
            },
            key: "entity",
            width: smallNormalRowWidth,
        },
        {
            header: {
                id: "company",
                label: t`Company`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`Company`,
            },
            valueFormatter: (row: any) => {
                return renderCompany(row);
            },
            key: "company",
            width: companyRowWidth,
        },
        {
            header: {
                id: "requster",
                label: t`Requester`,
                shouldBeFiltered: true,
                inputType: "select",
                optionsHeaderText: t`Select Requester`
            },
            key: "requester",
            width: smallNormalRowWidth,
        },
    ]
}

export const taxonomyDiemsnionValueColumns: <T>() => DataTableColumn<T>[] = () => {
    return [
        {
            header: {
                id: "dimension",
                label: t`Dimension`,
                optionsHeaderText: t`Dimension`,
                shouldBeFiltered: true,
                inputType: "text",
                width: 600,
            },
            key: "dimension",
            width: dimensionValuesRowWidth,
        },
        {
            header: {
                id: "value",
                label: t`Value`,
                shouldBeFiltered: true,
                optionsHeaderText: t`Select Value`,
                inputType: "select",
                width: 150,
            },
            key: "value",
            width: smallNormalRowWidth,
        },
        {
            header: {
                id: "isTypedMember",
                label: t`Is Typed Member`,
                shouldBeFiltered: true,
                optionsHeaderText: t`Select Is Typed Member`,
                inputType: "select",
                width: 250,
            },
            key: "isTypedMember",
            width: smallNormalRowWidth,
        },
        {
            header: {
                id: "isDefault",
                label: t`Is Default`,
                shouldBeFiltered: true,
                inputType: "select",
                width: 250,
            },
            key: "isDefault",
            width: smallNormalRowWidth,
        },
    ];
};

/* Renderers for columns with complex data 
------------------------------------------------------------------------ */

const renderTaxonomies = (row: any): JSX.Element => {
    if (row && Array.isArray(row.taxonomies)) {
        const taxonomyNames = getTaxonomyNames(row.taxonomies);
        return (
            <div>
                {taxonomyNames.map((name, index) => (
                    <div key={index}>{name}</div>
                ))}
            </div>
        );
    }
    return <></>;
};

const renderCompany = (row: any): string => {
    if (row && row.company && typeof row.company === "object") {
        return row.company.name || "";
    }
    return "";
};

const renderElement = (row: { elementReference: ElementReference }): string => {
    if (row) {
        return row?.elementReference?.taxonomyElement?.name || row?.elementReference?.extension?.name || row?.elementReference?.name || "";
    }
    return "";
};

const renderDimensionValues = (row: any): string => {
    if (row && Array.isArray(row?.dimensionValueReferences)) {
        return row.dimensionValueReferences?.map((dimensionValue: any) => {
            return dimensionValue?.dimensionValue?.dimensionName || dimensionValue?.taxonomyValue?.dimensionReference?.name || "";
        }).join(", ");
    }
    return "";
};

const renderisSafeLabel = (row: any): string => {
    if (row) {
        return row.isSafe ? `*${row.label}` : `${row.label} `
    };
    return "";
}

const renderDimension = (row: any): string => {
    if (row && row.dimension && typeof row.dimension === "object") {
        return row.dimension.name || "";
    }
    return "";
};

const renderAdditionalElements = (row: any): string => {
    if (row && Array.isArray(row.additionalElements)) {
        return row.additionalElements.join(", ");
    }
    return "";
};

const renderWider = (row: any): string => {
    if (row && row.labels) {
        return row.labels.map((label: any) => {
            return label.text;
        }, "").join(", ");
    }
    return "";
}