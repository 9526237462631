import axios from "axios";
import { UsageLogPayload, UsageLogResponse } from "./types";
import { getSelectedTaxonomyIds } from "../helpers/taxonomyHelpers";

export const getUsageLogs = async (id: string, activeTab: string, payload: UsageLogPayload): Promise<UsageLogResponse> => {
    const response = await axios.post(`/api/custom-objects/${activeTab}/${id}/usage`, payload);
    return response.data;
}

export const getLanguages = async (): Promise<string[]> => {
    const selectedTaxonomies = getSelectedTaxonomyIds();
    let url = 'api/taxonomies/languages';
    if (selectedTaxonomies && selectedTaxonomies.length > 0) {
        url += `?taxonomyIds=${selectedTaxonomies.join(',')}`;
    }
    const response = await axios.get<string[]>(url);
    return response.data;
}