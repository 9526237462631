import { Container, Typography } from "@mui/material";
import { dimensionReference } from "../../api/types";
import Grid from "@mui/material/Grid2";
import { getTaxonomyNames } from "../../helpers/taxonomyHelpers";

interface ElementDetailedViewProps {
    element: dimensionReference;
    isOption?: boolean;
}

const GridView = ({ label, value, size }: { label: string; value: string; size: number }) => {
    return (
        <Grid
            size={size}
            sx={{
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
            }}
        >
            <b style={{ marginRight: 5 }}>{label}</b>
            <span style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{value}</span>
        </Grid>
    );
};


export const DimensionDetailedView = ({
    element,
}: ElementDetailedViewProps) => {

    console.log(element)
    if (!element.customDimensionValue && element.taxonomyValue) {
        return (
            <>

                {/* <Container
                    maxWidth={false}
                    sx={{
                        display: "flex",
                        wordWrap: "break-word",
                        padding: 2,
                        color: "white",
                        backgroundColor: "rgb(0, 138, 92)",
                    }}
                >
                    <Grid container>
                        <Typography
                            variant="subtitle2"
                            sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {element?.taxonomyValue ? element?.taxonomyValue?.dimensionReference?.name : ""}
                        </Typography>
                    </Grid>
                </Container> */}
            </>
        );
    }

    return (
        <Container
            maxWidth={false}
            sx={{
                display: "flex",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                padding: 2,
            }}
        >
            <Grid container spacing={2}>
                <GridView label="Dimension Value" value={element?.customDimensionValue?.name || ""} size={8} />
                <GridView label="Parent" value={element?.customDimensionValue?.dimensionName || ""} size={4} />
                <GridView label="Company" value={element?.customDimensionValue?.customMemberName || ""} size={3} />
                <GridView label="Taxonomies" value={getTaxonomyNames(element?.customDimensionValue?.taxonomies || []).join(", ") || ""} size={3} />
                <GridView label="Children" value={element?.customDimensionValue?.labels?.map(
                    (label) => label?.text || ""
                ).join(", ") || ""} size={3} />
            </Grid>
        </Container>
    );
};
